import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container } from "../components/Grid"
import Hero from "../components/Hero"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Not Found" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Hero
        title="Page Not Found"
        image="placeholder-image.png"
        body="404 - Page Not Found"
      />
    </Section>
    <Section className="page-not-found">
      <Container>
        <div className="page-not-found">
          <h2>PAGE NOT FOUND</h2>
          <p>
            The page you were looking for cannot be found. <br />
            Please check the URL and make sure you're spelling it correctly, or
            use the main menu navigation to find what you're looking for.
          </p>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
